'use strict';

Gri.module({
  name: 'section-delta',
  ieVersion: null,
  $el: $('.section-delta'),
  container: '.section-delta',
  fn: function () {

    const $this = this.$el;
    $this.marquee({
      duplicated: true,
      duration: 20000,
      delayBeforeStart: 0,
      setStartPosition: 100
    });

  }
});
